import Cookies, { CookieAttributes } from 'js-cookie'

export const getAuthToken = () => Cookies.get('shopencode_token')
export const setAuthToken = (token?: string, options?: CookieAttributes) => {
  setCookie('shopencode_token', token, options)
}
export const removeAuthToken = () => {
  Cookies.remove('shopencode_token')
}

export const cookieExists = (name: string) => {
  return !!Cookies.get(name)
}

export const setCookie = (
  name: string,
  token?: string,
  options?: CookieAttributes
) => {
  if (!token) {
    Cookies.remove(name)
  } else {
    // TODO : get expiration date from config
    Cookies.set(name, token, options ?? { expires: 60 * 60 * 24 * 30 })
  }
}
