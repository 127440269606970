import * as Sentry from '@sentry/nextjs'

import { cookieExists } from '@framework/utils/cookies'
const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID

export const grantConsent = () => {
  try {
    if (cookieExists('accept_cookies')) {
      window.fbq('consent', 'grant')
    }
  } catch (err) {
    Sentry.captureException(err)
  }
}

export const pageview = () => {
  try {
    window.fbq('consent', 'revoke')
    window.fbq('init', FB_PIXEL_ID)
    window.fbq('track', 'PageView')
    grantConsent()
  } catch (err) {
    Sentry.captureException(err)
  }
}

export const viewItem = (item: any) => {
  try {
    window.fbq('track', 'ViewContent', {
      content_name: item.name,
      content_category: item.category.name || '',
      content_ids: [item.variant.sku],

      content_type: 'product',
      value: Number(item.price.value),
      currency: item.price.currencyCode,
    })
  } catch (err) {
    Sentry.captureException(err)
  }
}

export const addToCart = (item: any, quantity: number) => {
  try {
    window.fbq('track', 'AddToCart', {
      content_name: item.variant.sku,
      content_category: item.category
        ? item.category.name
        : item.variant?.category?.name || '', // TODO : improve this logic later.,
      contents: [
        {
          id: item.variant.sku,
          quantity,
        },
      ],
      content_type: 'product',
      value: item.price.value,
      currency: item.price.currencyCode,
    })
  } catch (err) {
    Sentry.captureException(err)
  }
}

export const purchase = (order: any) => {
  try {
    window.fbq('track', 'Purchase', {
      contents: (order as any)?.lineItems.map((lineItem: any) => {
        return {
          id: lineItem.variant.sku,
          quantity: Number(lineItem.quantity),
        }
      }),
      content_type: 'product',
      value: order.totalPrice,
      currency: order.currency.code,
    })
  } catch (err) {
    Sentry.captureException(err)
  }
}
