export default class Tracking {
  analyticsID: string | undefined

  constructor(analyticsID?: string) {
    if (analyticsID) {
      this.analyticsID = analyticsID
    }
  }

  pageview(url: string) {}

  viewItemList(item_list_id: string, item_list_name: string, items: any) {}

  viewItem(item: any) {}

  addToCart(item: any, quantity: number) {}

  removeFromCart(item: any, quantity: number) {}

  checkout(basket: any, event: string) {}

  purchase(order: any, orderCode: string) {}
}
