import * as Sentry from '@sentry/nextjs'
import Tracking from './base'
import { cookieExists } from '@framework/utils/cookies'

export default class GoogleAnalytics extends Tracking {
  constructor(analyticsID: string) {
    super(analyticsID)
  }

  pageview(url: string) {
    window.gtag('config', this.analyticsID, {
      page_path: url,
    })
  }

  viewItemList(item_list_id: string, item_list_name: string, items: any) {
    try {
      window.gtag('event', 'view_item_list', {
        items: (items as any)?.map((item: any, index: number) => {
          return {
            id: item.sku,
            name: item.name,
            list_name: item_list_name,
            //brand,
            //category
            //variant
            list_position: index,
            price: item.price.value,
          }
        }),
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  viewItem(item: any) {
    try {
      window.gtag('event', 'view_item', {
        items: [
          {
            id: item.variant.sku,
            name: item.name,
            brand: item.customFields.brand || '',
            category: item.category.name || '',
            //"variant": "Black",
            //"quantity": 2,
            price: item.price.value,
          },
        ],
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  addToCart(item: any, quantity: number) {
    try {
      window.gtag('event', 'add_to_cart', {
        items: [
          {
            id: item.variant.sku,
            name: item.name,
            //"list_name": "Search Results",
            brand: item.variant.brand || '',
            category: item.category
              ? item.category.name
              : item.variant?.category?.name || '', // TODO : improve this logic later.
            //"variant": "Black",
            quantity: quantity,
            price: item.price.value,
          },
        ],
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  removeFromCart(item: any, quantity: number) {
    try {
      window.gtag('event', 'remove_from_cart', {
        items: [
          {
            id: item.variant.sku,
            name: item.name,
            brand: item.variant.brand || '',
            category: item.variant?.category?.name || '',
            //"variant": "Black",
            quantity: quantity,
            price: item.price.value,
          },
        ],
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  checkout(basket: any, event: string) {
    try {
      if (event === 'begin_checkout') {
        window.gtag('event', 'begin_checkout', {
          items: (basket as any)?.lineItems.map(
            (lineItem: any, index: number) => {
              return {
                id: 'P12345',
                name: lineItem.name,
                brand: lineItem.variant.brand || '',
                category: lineItem.variant?.category?.name || '',
                //"variant": "Black",
                quantity: Number(lineItem.quantity),
                price: lineItem.variant.price,
              }
            }
          ),
          //coupon: '',
        })
      }
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  purchase(order: any, orderCode: string) {
    try {
      window.gtag('event', 'purchase', {
        transaction_id: orderCode,
        //"affiliation": "Google online store",
        value: order.totalPrice,
        currency: order.currency.code,
        tax: order.totalTax,
        shipping: order.shippingPrice,
        items: (order as any)?.lineItems.map((lineItem: any, index: number) => {
          return {
            id: lineItem.variant.sku,
            name: lineItem.name,
            brand: lineItem.variant.brand || '',
            category: lineItem.variant?.category?.name || '',
            //"variant": "Red",
            quantity: Number(lineItem.quantity),
            price: lineItem.variant.price,
          }
        }),
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }
}
