import '@assets/main.css'
import '@assets/chrome-bug.css'
import { FC, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { Head } from '@theme/common'
import { ManagedUIContext } from '@components/ui/context'
import { appWithTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import ga from '@lib/ga'
import * as fb from '@lib/fb-pixel'

const Noop: FC = ({ children }) => <>{children}</>

const MyApp = ({ Component, pageProps }: AppProps) => {
  const Layout = (Component as any).Layout || Noop
  const router = useRouter()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useEffect(() => {
    ga.pageview(router.asPath as string)
    fb.grantConsent()
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url)
      fb.pageview()
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Head />
      <ManagedUIContext>
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </ManagedUIContext>
    </>
  )
}

export default appWithTranslation(MyApp)
