import * as Sentry from '@sentry/nextjs'

import Tracking from './base'

export default class GoogleAnalytics4 extends Tracking {
  constructor(analyticsID: string) {
    super(analyticsID)
  }

  pageview(url: string) {
    window.gtag('config', this.analyticsID, {
      page_path: url,
    })
  }

  viewItemList(item_list_id: string, item_list_name: string, items: any) {
    try {
      window.gtag('event', 'view_item_list', {
        item_list_id,
        item_list_name,
        items: (items as any)?.map((item: any, index: number) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            //affiliation: "Google Store",
            //coupon: "SUMMER_FUN",
            currency: item.price.currencyCode,
            //discount: 2.22,
            index,
            // item_brand: 'Google',
            // item_category: 'Apparel',
            // item_category2: 'Adult',
            // item_category3: 'Shirts',
            // item_category4: 'Crew',
            // item_category5: 'Short sleeve',
            item_list_id: item_list_id,
            item_list_name: item_list_name,
            //item_variant: 'green',
            //location_id: 'L_12345',
            price: item.price.value,
            quantity: 1,
          }
        }),
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  viewItem(item: any) {
    try {
      window.gtag('event', 'view_item', {
        currency: item.price.currencyCode,
        value: item.price.value,
        items: [
          {
            item_id: item.variant.sku,
            item_name: item.name,
            // affiliation: 'Google Store',
            // coupon: 'SUMMER_FUN',
            currency: item.price.currencyCode,
            // discount: 2.22,
            item_brand: item.customFields.brand || '',
            item_category: item.category.name || '',
            // item_category2: 'Adult',
            // item_category3: 'Shirts',
            // item_category4: 'Crew',
            // item_category5: 'Short sleeve',
            item_variant: item.variant.color, //TODO : add variant value using color and size
            price: item.price.value,
            quantity: 1,
          },
        ],
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  addToCart(item: any, quantity: number) {
    try {
      window.gtag('event', 'add_to_cart', {
        currency: item.price.currencyCode,
        value: item.price.value,
        items: [
          {
            item_id: item.variant.sku,
            item_name: item.name,
            // affiliation: 'Google Store',
            // coupon: 'SUMMER_FUN',
            currency: item.price.currencyCode,
            // discount: 2.22,
            item_brand: item.variant.brand || '',
            item_category: item.category
              ? item.category.name
              : item.variant?.category?.name || '', // TODO : improve this logic later.
            // item_category2: 'Adult',
            // item_category3: 'Shirts',
            // item_category4: 'Crew',
            // item_category5: 'Short sleeve',
            item_variant: item.variant.name, // TODO : add variant value using color and size
            price: item.price.value,
            quantity,
          },
        ],
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  removeFromCart(item: any, quantity: number) {
    try {
      window.gtag('event', 'remove_from_cart', {
        currency: item.price.currencyCode,
        value: item.price.value,
        items: [
          {
            item_id: item.variant.sku,
            item_name: item.name,
            // affiliation: 'Google Store',
            // coupon: 'SUMMER_FUN',
            currency: item.price.currencyCode,
            // discount: 2.22,
            item_brand: item.variant.brand || '',
            item_category: item.variant?.category?.name || '',
            // item_category2: 'Adult',
            // item_category3: 'Shirts',
            // item_category4: 'Crew',
            // item_category5: 'Short sleeve',
            item_variant: item.variant.name,
            price: item.price.value,
            quantity,
          },
        ],
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  checkout(basket: any, event: string) {
    try {
      window.gtag('event', event, {
        currency: basket?.currency.code,
        value: basket?.totalPrice,
        items: (basket as any)?.lineItems.map(
          (lineItem: any, index: number) => {
            return {
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              //affiliation: "Google Store",
              //coupon: "SUMMER_FUN",
              currency: basket.currency.code,
              //discount: 2.22,
              index,
              item_brand: lineItem.variant.brand || '',
              item_category: lineItem.variant?.category?.name || '',
              // item_category2: "Adult",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              item_variant: lineItem.variant.name,
              price: lineItem.variant.price,
              quantity: Number(lineItem.quantity),
            }
          }
        ),
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }

  purchase(order: any, orderCode: string) {
    try {
      window.gtag('event', 'purchase', {
        currency: order.currency.code,
        transaction_id: orderCode,
        value: order.totalPrice,
        //affiliation: "Google Store",
        //coupon: "SUMMER_FUN",
        shipping: order.shippingPrice,
        tax: order.totalTax,
        items: (order as any)?.lineItems.map((lineItem: any, index: number) => {
          return {
            item_id: lineItem.variant.sku,
            item_name: lineItem.name,
            //affiliation: "Google Store",
            //coupon: "SUMMER_FUN",
            currency: order.currencyCode,
            //discount: 2.22,
            index,
            item_brand: lineItem.variant.brand || '',
            item_category: lineItem.variant?.category?.name || '',
            // item_category2: "Adult",
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            // item_list_id: "related_products",
            // item_list_name: "Related Products",
            item_variant: lineItem.variant.name,
            price: lineItem.variant.price,
            quantity: Number(lineItem.quantity),
          }
        }),
      })
    } catch (err) {
      Sentry.captureException(err)
    }
    return true
  }
}
