import Base from './tracking/base'
import GoogleAnalytics from './tracking/analytics'
import GoogleAnalytics4 from './tracking/g4'

const trackingID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS

export default trackingID
  ? trackingID.indexOf('UA-') > -1
    ? new GoogleAnalytics(trackingID!)
    : new GoogleAnalytics4(trackingID)
  : new Base()
